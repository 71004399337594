<template>
  <div class="box_body">
    <div class="fx_img">
      <img src="@/assets/images/fenxbj.png" alt="" />
    </div>
    <div class="foot_fx">
      <div class="hb" @click="fxhb">
        <img src="@/assets/images/haibao.png" alt="" />
        <p class="fxhb">分享海报</p>
      </div>
      <div class="hb" @click="copyClick">
        <img src="@/assets/images/fxhy.png" alt="" />
        <p class="fxhb">复制链接</p>
      </div>
    </div>
    <van-overlay :show="showPoster">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="B_img">
            <img class="bjes" src="@/assets/images/fx.jpg" alt="" />
            <vue-qr
              class="qrCode"
              :logoSrc="logoImg"
              :text="shareUrl"
              :logoScale="50"
              :size="400"
            ></vue-qr>
            <div class="sbtz" v-if="showPoster">
              <div class="ezs">长按识别图中二维码</div>
              <div class="revc" @click="qxfx">
                <img src="@/assets/images/quxiao.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import { getSelfShareUrl } from "@/request/api";
export default {
  components: {
    vueQr,
  },
  // name: "vueqr",
  data() {
    return {
      showPoster: false,
      logoImg: require("../../assets/images/logo.jpg"),
      shareUrl: "",
    };
  },
  created() {
    this.getSelfShare();
  },
  methods: {
    // 获取分销链接
    getSelfShare() {
      getSelfShareUrl().then((res) => {
        console.log(res);
        if (res.code == "200") {
          this.shareUrl = res.data.shareUrl;
        }
      });
    },

    // 打开海报的弹框
    fxhb() {
      this.showPoster = true;
    },
    copyClick() {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", this.shareUrl);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$toast.success("复制成功");
    },
    // 取消海报的弹框
    qxfx() {
      this.showPoster = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box_body {
  position: relative;
  .fx_img {
    width: 90%;
    margin: 0 auto;
    margin-top: 1rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
    }
  }
  .foot_fx {
    width: 90%;
    display: flex;
    background: #f4f5f6;
    border-radius: 1rem;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 4rem;
    .hb {
      display: flex;
      width: 50%;
      padding-top: 1rem;
      padding-bottom: 1rem;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 2rem;
        height: 2rem;
        text-align: center;
      }
      .fxhb {
        margin-top: 0.4rem;
        margin-bottom: 0;
      }
    }
  }
  .sbtz {
    position: absolute;
    width: 100%;
    top: 110%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    .ezs {
      color: #ffffff;
      font-size: 1rem;
      text-align: center;
    }
    .revc {
      width: 2rem;
      height: 2rem;
      margin: 0 auto;
      margin-top: 1rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 80%;

  margin-top: -9rem;
  border-radius: 0.8rem;
  .B_img {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .bjes {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
    }
    .qrCode {
      position: absolute;
      width: 8rem;
      height: 8rem;
      top: 50%;
      left: 50%;
      margin-left: -4rem;
      margin-top: -1.6rem;
    }
  }
}
</style>
